import Search from './src/screens/Search';
import Splash from './src/components/Splash';
import Toast from './src/components/Toast';
import Celebrity from './src/screens/Celebrity'
import Back from './src/components/Back';

import { useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { report, searchCelebrity } from './src/service/apiCelibrity'
import AsyncStorage from '@react-native-async-storage/async-storage';
import TermsOfUse from './src/screens/TermsOfUse';
import Modal from './src/components/Modal';


export default function App() {

  const [searchVisibility, setSearchVisibility] = useState(true)
  const [splashVisibility, setSplashVisibility] = useState(false)
  const [celebrityVisibility, setCelebrityVisibility] = useState(false)
  const [toastVisibility, setToastVisibility] = useState(false)
  const [backVisibility, setBackVisibility] = useState(false)
  const [termsOfUseVisibility, setTermsOfUseVisibility] = useState(false)
  const [modalVisibility, setModalVisibility] = useState(false);
  

  
  const [toastText, setToastText] = useState("")
  const [celebrity, setCelebrity] = useState({})
  const [globalOpacity, setGlobalOpacity] = useState(1);
  const [answer, setAnswer] = useState(false)
  const [name, setName] = useState("");

  const openModal = () => {
    setGlobalOpacity(0.05);
    setModalVisibility(true)
  }

  const closeModal = (res) => {
    setGlobalOpacity(1);
    setModalVisibility(false);
    if(res) {
      goHome()
      report(celebrity._id, name)
      setToastText(`Our team is working on to correct this error.`)
      setToastVisibility(true)
    }
  }

  const goHome = () => {
    setCelebrityVisibility(false);
    setBackVisibility(false)
    setTermsOfUseVisibility(false)
    setSearchVisibility(true)
  }

  const goTermsOfUse = () => {
    setSearchVisibility(false);
    setTermsOfUseVisibility(true);
    setBackVisibility(true)
  }
  
  const search = (text) => {
    if(text === "") return
    setName(text);
    text = text.split(' ')
               .filter((c) => c!=="")
               .join(' ')
    setSearchVisibility(false)
    setSplashVisibility(true)  
    searchCelebrity(text)
    .then(celebrity => {
      setCelebrity(celebrity)
      Image.prefetch(celebrity.imageUrl)
      .then(async done => {
        const userSelect = await AsyncStorage.getItem(celebrity._id);
        setSplashVisibility(false)
        setCelebrityVisibility(true)
        setBackVisibility(true)
        if(userSelect !== null){
          setToastText('The pointy hat can only be used once on each celebrity');
          setToastVisibility(true);
          setAnswer(true)
        }
        setSplashVisibility(false)
        setCelebrityVisibility(true)
        setBackVisibility(true)
      })
      .catch(() => {
        setSplashVisibility(false)
        setSearchVisibility(true)
        setToastText(`It seems that ${text} is not famous...`)
        setToastVisibility(true)
      })
    })
    .catch((error) => {
      if(error === 404) {
        setSplashVisibility(false)
        setSearchVisibility(true)
        setToastText(`It seems that ${text} is not famous...`)
        setToastVisibility(true)
      }
      else {
        setSplashVisibility(false)
        setSearchVisibility(true)
        setToastText(`The service is unavailable, please contact support team...`)
        setToastVisibility(true)
      }
    })
  }
  return (
    <View style={styles.container}>
      <View style={{...styles.container, opacity: globalOpacity}}>
        <Search onDone={search} visible={searchVisibility} onTermsOfUse={goTermsOfUse}/>
        <Splash visible={splashVisibility}/>
        <Celebrity celebrity={celebrity} setCelebrity={setCelebrity} answer={answer} setAnswer={setAnswer} visible={celebrityVisibility} onBack={goHome} onOpenModal={openModal}/>
        <Toast text={toastText} visible={toastVisibility} setVisible={setToastVisibility}/>
        <TermsOfUse visible={termsOfUseVisibility} onBack={goHome}/>
        <Back visible={backVisibility} onPress={goHome}/>
        <Modal visible={modalVisibility} closeModal={(res) => closeModal(res)}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#10141b',
  },
});


