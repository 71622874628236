import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { IMAGES } from "../utils/images";
import { moderateScale, verticalScale } from "../utils/scaling";

export default function Proposition(props) {

  return (
    <TouchableOpacity onPress={props.onPress}>
      <Image
        style={{
          height: verticalScale((IMAGES[props.house].height),0.3),
          width: verticalScale((IMAGES[props.house].width), 0.3)}}
        source={IMAGES[props.house].uri}
        resizeMode='contain'
      />
    </TouchableOpacity>
  )
}
