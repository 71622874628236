import { useEffect, useRef, useState } from 'react';
import { Dimensions, FlatList, Image, Keyboard, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { getSuggestion } from '../service/apiCelibrity';
import { moderateScale, scale, verticalScale } from '../utils/scaling';

export default function Search(props) {

  const [text, setText] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [margin, setMargin] = useState(30)
  const inputRef = useRef();
  const { width, height } = Dimensions.get('window');

  const done = (t) => {
    props.onDone(t)
    setText("")
    setSuggestion([])
  }

  const onChangeText = (text) => {
    setText(text);
    if(text === "") {
      setSuggestion([]);
      return
    }
    getSuggestion(text)
      .then(s => setSuggestion(s.map(c => {return {key: c}})))
      .catch(e => console.error(e));
  }

  const onKeyPress = (key) => {
    if(key.key === 'Enter') done(text)
  }

  const onBlur = () => {
    setMargin(30);
  }

  const onFocus = () => {
    if(height>width) setMargin(100);
  }

  return (
    props.visible ?
      <View style={styles.container}>
        <Text style={styles.title}>GUESS A CELEBRITY HOUSE</Text>
        <View style={{marginBottom: scale(margin)}}>
          <View style={{...styles.search, borderBottomLeftRadius: suggestion.length === 0 ? 10 : 0, borderBottomRightRadius: suggestion.length === 0 ? 10 : 0}}>
            <TextInput 
              style={styles.input} 
              onChangeText={onChangeText}
              placeholder="Enter a celebrity name"
              onSubmitEditing={Platform.OS === 'web' ? null : () => done(text)}
              placeholderTextColor='#544f3a'
              cursorColor='#d5ab03'
              returnKeyType='done'
              onKeyPress={Platform.OS === 'web' ? onKeyPress : null}
              value={text}
              ref={inputRef}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <TouchableOpacity onPressOut={()=>done(text)}>
              <Image 
                style={styles.sortingHat} 
                source={require('../../assets/icon.png')}
              />
            </TouchableOpacity>
          </View>
          <View>
            {
              suggestion.length === 0 ? null :
              <FlatList 
                data={suggestion}
                style={styles.suggestionList}
                renderItem={({item}) => 
                  <TouchableOpacity onPressOut={()=>{setText(item.key); done(item.key)}}>
                    <Text style={styles.item}>{item.key}</Text>
                  </TouchableOpacity>
                }
              />
            }
          </View>
        </View>
        <TouchableOpacity onPressOut={props.onTermsOfUse} style={styles.legalContainer}>
          <Text style={styles.legal}>Terms of Use</Text>
        </TouchableOpacity>
      </View>
    : null
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: moderateScale(20, 0.2),
    marginBottom: moderateScale(20, 0.4),
    fontFamily: 'serif',
    color: '#d5ab03'
  },
  input: {
    fontSize: moderateScale(15, 0.1),
    width: moderateScale(180, 0.1),
    outlineStyle : 'none',
    color: '#d5ab03',
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    borderColor: '#d5ab03'
  },
  suggestionList: {
    position: 'absolute',
    top:0,
    left:0,
    padding: 10,
    borderWidth: 1,
    borderTopWidth:0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderColor: '#d5ab03',
    outlineStyle : 'none',
  },
  item: {
    fontSize: moderateScale(15, 0.1),
    width: moderateScale(180, 0.1) + verticalScale(25),
    outlineStyle : 'none',
    color: '#544f3a',
  },
  sortingHat: {
    height: verticalScale(25),
    width: verticalScale(25),
  },
  legalContainer:{
    position: 'absolute',
    bottom: 5,
  },
  legal: {
    color: 'white',
    opacity: 0.2
  }
});