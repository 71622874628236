import { verticalScale } from "../utils/scaling"
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View, Modal as ReactModal, Pressable } from "react-native";


export default function Modal(props) {
  return (
    <ReactModal
        animationType="fade"
        transparent={true}
        visible={props.visible}
        onRequestClose={() => props.closeModal(false)}>
        <View style={styles.container}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>This picture do not correspond to the celebrity searched ?</Text>
            <View style={styles.modalResponse}>
              <Pressable style={styles.modalCancel} onPress={() => props.closeModal(false)}>
                <Text style={styles.modalCancelTxt}>Cancel</Text>
              </Pressable>
              <Pressable style={styles.modalYes} onPress={() => props.closeModal(true)}>
                <Text style={styles.modalYesTxt}>Yes</Text>
              </Pressable>
            </View>
            
          </View>
        </View>
      </ReactModal>
  )
}

const styles = StyleSheet.create({
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalResponse: {
    flexDirection: "row",
    gap: 20
  },
  modalCancel: {
    borderRadius: 15,
    backgroundColor: '#d5ab03',
    paddingHorizontal: 8,
    paddingVertical: 5,
    width: 100,
  },
  modalYes: {
    borderRadius: 15,
    backgroundColor: '#d5ab03',
    paddingHorizontal: 8,
    paddingVertical: 5,
    width: 100
  },
  modalCancelTxt: {
    color: '#10141b',
    fontSize: verticalScale(15),
    textAlign: "center"
  },
  modalYesTxt: {
    color: '#10141b',
    fontSize: verticalScale(15),
    textAlign: "center"
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: verticalScale(20),
  },
  modalText: {
    fontSize: verticalScale(20),
    marginBottom: verticalScale(15),
    color: '#d5ab03'
  },
})