import { useEffect, useRef } from 'react';
import { Animated, Image, StyleSheet, SafeAreaView, Platform  } from 'react-native';


export default function Splash(props) {

  const fadeAnim = useRef(new Animated.Value(0.3)).current  // Initial 
  const zoomAnim = useRef(new Animated.Value(0.9)).current  // Initial 


  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.parallel([
          Animated.timing(
            fadeAnim,
            {
              toValue: 1,
              duration: 1000,
              useNativeDriver: false
            }
          ),
          Animated.timing(
            zoomAnim,
            {
              toValue: 1.2,
              duration: 1000,
              useNativeDriver: false
            }
          ),
        ]),
        Animated.parallel([
          Animated.timing(
            fadeAnim,
            {
              toValue: 0.3,
              duration: 1000,
              useNativeDriver: false
            }
          ),
          Animated.timing(
            zoomAnim,
            {
              toValue: 0.9,
              duration: 1000,
              useNativeDriver: false
            }
          ),
        ])
      ])
    ).start();
    
  }, [fadeAnim, props.visible])
  
  return (
    props.visible ?
    <SafeAreaView  style={{...styles.screenCover}}>
      <Animated.View style={{...styles.loadingContainer, transform: [{ scale: zoomAnim }], opacity: fadeAnim}}>
        <Image 
          style={styles.sortingHatLoading} 
          source={require('../../assets/icon.png')}
        />
      </Animated.View>
    </SafeAreaView>
    : null
  )
}


const styles = StyleSheet.create({
  screenCover: {
    flex: 1,
    backgroundColor: '#10141b',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    alignItems: "center", 
    justifyContent: "center",
  },
  sortingHatLoading: {
    height: Platform.OS === 'web'? 200 : 120,
    width: Platform.OS === 'web'? 200 : 120,
  },
});