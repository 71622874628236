import { useEffect, useRef, useState } from "react";
import { View, Text, StyleSheet, Dimensions, Platform, Animated } from "react-native";

export default function Toast(props) {

  const fadeAnim = useRef(new Animated.Value(0)).current  // Initial 

  const enterTiming = 500;
  const stayTiming = 3000;
  const exitTiming = 500;

  useEffect(() => {
    if(!props.visible) return
    setTimeout(() => props.setVisible(false), enterTiming+stayTiming+exitTiming)
    Animated.sequence([
      Animated.timing(
        fadeAnim,
        {
          toValue: 1,
          duration: enterTiming,
          useNativeDriver: true
        }
      ),
      Animated.timing(
        fadeAnim,
        {
          toValue: 1,
          duration: stayTiming,
          useNativeDriver: true
        }
      ),
      Animated.timing(
        fadeAnim,
        {
          toValue: 0,
          duration: exitTiming,
          useNativeDriver: true
        }
      )
    ]).start();
  }, [props, fadeAnim])

  return (
    props.visible ?
    <View style={styles.globalToastContainer}>
      <Animated.View style={{...styles.toastContainer, opacity: fadeAnim}}>
        <Text style={styles.message}>{props.text}</Text>
      </Animated.View>
    </View>
    : null
  )
}

const styles = StyleSheet.create({
  globalToastContainer: {
    position: 'absolute',
    left: 0,
    bottom: /*Platform.OS==='android' ? (Dimensions.get('screen').height - Dimensions.get('window').height)+30:*/ 30,
    alignItems: 'center',
    justifyContent:'center',
    width: '100%'
  },
  toastContainer: {
    borderRadius: 50,
    backgroundColor: '#ebedf0',
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  message: {
    textAlign: 'center'
  }
});