import { View, StyleSheet, Image, Dimensions, Platform, TouchableOpacity } from "react-native";
import { moderateScale, scale } from "../utils/scaling";

export default function Back(props) {

  return (
    props.visible ?
    <View style={styles.container}>
      <TouchableOpacity onPress={props.onPress}>
      <Image style={styles.back} source={require('../../assets/back.png')}/>
      </TouchableOpacity>
    </View>
    : null
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: moderateScale(15,0.1),
    top: Platform.OS==='android' ? moderateScale(45):moderateScale(15,0.1),
    alignItems: 'flex-start'
  },
  back: {
    width:moderateScale(20, 0.1),
    height:moderateScale(20, 0.1)
  }

});