import { StyleSheet, Text, View, Linking, TouchableOpacity } from "react-native";

export default function TermsOfUse(props) {
  
  return props.visible ? (
    <View style={styles.container}>
      <Text style={styles.terms}>
        The Pointy Hat utilizes the concept of a pointy hat that chose a house for a celebrity, but is not associated with any other pointy hat, including 
        a potential pointy hat in a magician's school. In the same way, the houses described by animals are in no way references of a magician's world. 
        These animals have been chosen completely randomly. Also the celebrities associated with these houses do not reflect any opinion about them. 
        All photos and illustrations used on this site respect the associated licenses (
        <Text style={styles.link} onPress={() => Linking.openURL('https://creativecommons.org/licenses/by-sa/4.0')}>CC BY-SA 4.0</Text>).
        If you have any comments or complaints, please contact us using the contact information below.
      </Text>
      <Text style={styles.namee}>Martin Bruel</Text>
      <Text style={styles.mail}>martin.bruel@outlook.com</Text>
      <TouchableOpacity onPress={props.onBack} style={styles.legalContainer}>
        <Text style={styles.legal}>Back</Text>
        </TouchableOpacity>
    </View>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  terms:{
    maxWidth: 500,
    textAlign: 'center',
    color: 'white',
    margin: 50
  },
  link:{
    textDecorationLine: 'underline',
  },
  namee: {
    marginTop: 15,
    color: 'white',
    fontWeight: 'bold',
    
  },
  mail: {
    color: 'white',
    marginBottom: 50
  },
  legalContainer:{
    position: 'absolute',
    bottom: 5,
  },
  legal: {
    color: 'white',
    opacity: 0.2
  }
})