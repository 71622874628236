import { useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View, Modal, Pressable } from "react-native";
import Proposition from "../components/Proposition";
import { IMAGES } from "../utils/images";
import { moderateScale, scale, verticalScale } from "../utils/scaling";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { vote } from "../service/apiCelibrity";

export default function Celebrity(props) {

  const houses = ['lion', 'snake', 'badger', 'eagle']
  const [percent, setPercent] = useState(0)
  const [resHouse, setResHouse] = useState(houses[0])



  const selectHouse = (house) => {
    vote(props.celebrity._id, house)
    const celebrity = props.celebrity;
    celebrity.houses[house]++;
    props.setCelebrity(celebrity);
    AsyncStorage.setItem(props.celebrity._id, "done");
    props.setAnswer(true)
  }

  useEffect(() => {
    if(!props.visible) props.setAnswer(false)
    if(props.visible && props.answer) {
      const maxIndex = props.celebrity.houses.indexOf(Math.max(...props.celebrity.houses))
      setResHouse(houses[maxIndex]);
      const total = props.celebrity.houses.reduce((prev, curr) => prev+curr)
      setPercent(Math.round(props.celebrity.houses[maxIndex] / total * 100))
    }
  }, [props.visible, props.answer])

  return (
    props.visible ?
    <View style={styles.container}>
      <View style={styles.profil}>
        
          <Text style={styles.profilName}>{props.celebrity.name}</Text>
          <Image 
            style={styles.profilImage} 
            source={{uri: props.celebrity.imageUrl}} 
          />
          <TouchableOpacity style={styles.reportContainer} onPress={props.onOpenModal}>
            <View style={styles.reportBtn}>
              <Text style={styles.reportTxt}>!</Text> 
            </View>
            
          </TouchableOpacity>
        
      </View>
      {
        !props.answer ?
        <View style={styles.question}>
          <Text style={styles.questionText}>Select below the house in which is {props.celebrity.name}</Text>
          <View style={styles.proposition}>
            {houses.map((n,i) =>
              <View style={styles[n]} key={i}>
                <Proposition
                  house={n} 
                  onPress={() => selectHouse(i)}
                />
              </View>
            )}
          <Image source={IMAGES['h'].uri} style={styles.h}/>
          </View>
        </View>
        :
        <View style={styles.question}>
          <Text style={styles.questionText}>{percent}% think that {props.celebrity.name} is:</Text>
          <View style={styles.imageContainer}>
            <Image
              source={IMAGES[resHouse+"_blason"].uri}
              resizeMode='contain'
              style={styles.resImage}
            />
          </View>
          <TouchableOpacity style={styles.button} onPress={props.onBack}>
            <Text style={styles.buttonText}>Search another</Text>
          </TouchableOpacity>
        </View>
      }
      
    </View>
    :null
  )
}

const styles = StyleSheet.create({
  reportContainer: {
    padding:verticalScale(10),
    position: 'absolute',
    top: 0,
    right: 0,
  },
  reportBtn:{
    borderRadius: 15,
    backgroundColor: '#d5ab03',
    alignItems: "center",
    justifyContent: "center",
    height: verticalScale(10),
    width: verticalScale(10),
    textAlign: "center"
  },
  reportTxt:{
    color: '#10141b',
    fontSize: verticalScale(8),
    fontWeight: "900"
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: verticalScale(20),
  },
  profil: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilName: {
    fontSize: verticalScale(20),
    marginBottom: verticalScale(15),
    color: '#d5ab03'
  },
  profilImage: {
    height: verticalScale(180),
    width: verticalScale(200),
    resizeMode: 'contain'
  },
  questionText:{
    fontSize: verticalScale(20),
    color: '#d5ab03',
    textAlign: 'center',
    marginBottom: verticalScale(10)
  },
  question: {
    marginTop: verticalScale(15),
    alignItems: 'center',
    justifyContent: 'center',
  },
  proposition: {
    width: verticalScale(IMAGES['lion'].width + IMAGES['snake'].width,0.3),
    height: verticalScale(IMAGES['lion'].height + IMAGES['badger'].height,0.3)
  },
  h: {
    width: verticalScale(IMAGES['h'].width,0.3),
    height: verticalScale(IMAGES['h'].height,0.3),
    position: 'absolute',
    left: verticalScale(268.5,0.3),
    top: verticalScale(315,0.3),
  },
  lion:{
    position: 'absolute',
    left: 0,
    top: 0,
  },
  snake:{
    position: 'absolute',
    right: 0,
    top: 0,
  },
  badger:{
    position: 'absolute',
    left: verticalScale(IMAGES['lion'].width - IMAGES['badger'].width,0.3),
    top: verticalScale(IMAGES['lion'].height,0.3),
  },
  eagle:{
    position: 'absolute',
    right: verticalScale(IMAGES['snake'].width - IMAGES['eagle'].width,0.3),
    top: verticalScale(IMAGES['snake'].height,0.3),
  },
  imageContainer: {
    marginTop: verticalScale(5),
  },
  resImage:{
    marginTop: verticalScale(15),
    height: verticalScale(IMAGES['snake_blason'].height,0.45),
    width: verticalScale(IMAGES['snake_blason'].width,0.45),
  },
  button: {
    borderRadius: 15,
    backgroundColor: '#d5ab03',
    paddingHorizontal: 8,
    paddingVertical: 5,
    marginTop: 15,
    marginBottom: verticalScale(IMAGES['snake_blason'].width, 0.085)
  },
  buttonText: {
    color: '#10141b',
    fontSize: verticalScale(15),
  },
});