export const IMAGES = {
  lion: {
    width: 385,
    height: 456,
    uri: require('../../assets/lion.png')
  },
  snake: {
    width: 385,
    height: 455,
    uri: require('../../assets/snake.png')
  },
  badger: {
    width: 367,
    height: 447,
    uri: require('../../assets/badger.png')
  },
  eagle: {
    width: 366,
    height: 445,
    uri: require('../../assets/eagle.png')
  },
  h: {
    width: 231,
    height: 270,
    uri: require('../../assets/h.png')
  },
  eagle_blason: {
    width: 364,
    height: 400,
    uri: require('../../assets/eagle_blason.png')
  },
  lion_blason: {
    width: 364,
    height: 400,
    uri: require('../../assets/lion_blason.png')
  },
  badger_blason: {
    width: 364,
    height: 400,
    uri: require('../../assets/badger_blason.png')
  },
  snake_blason: {
    width: 364,
    height: 400,
    uri: require('../../assets/snake_blason.png')
  },
}