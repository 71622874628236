import Constants from 'expo-constants';
import { getLocales } from 'expo-localization';

const BASE_URL = (Constants.expoConfig.extra.env === 'prod' ? 'https' : 'http') + '://' + Constants.expoConfig.extra.celebrityHost + ':' + Constants.expoConfig.extra.celebrityPort

export const searchCelebrity = async (name) => {

  const localisation = getLocales()[0].languageCode
  const url = `${BASE_URL}/celebrity/${localisation}/${name}`
  return fetch(url)
    .then(response => {
      if (response.ok) return response.json();
      if(response.status === 400 || response.status === 404) throw 404;
      throw 500;
    })
}

export const getSuggestion = async (text) => {

  const url = `${BASE_URL}/celebrity/suggestion/${text}`
  return fetch(url)
    .then(response => {
      if (response.ok) return response.json();
      if(response.status === 400 || response.status === 404) throw 404;
      throw 500;
    })
}

export const vote = async (celebrityId, house) => {
  const url = `${BASE_URL}/celebrity/vote`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      celebrityId,
      house
    }),
  });
}

export const report = async (celebrityId, name) => {
  const url = `${BASE_URL}/celebrity/report`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      celebrityId,
      name
    }),
  });
}